<template>
  <div class="device-container">
    <div class="title-heading">
      <p>{{ $t("Rate Your Experience") }}</p>
    </div>
    <div class="rate">
      <div v-for="(star, index) in maxStars" :key="index" @click="updateRating(index)">
        <img :src="starImage(index)" :alt="starAltText(star)" />
      </div>
    </div>
    <div class="sub-heading">
      <!-- <p>{{ $t("Please enter some feedback to improve us") }}</p> -->
      <p style="color:red" v-if="errormessage">{{ $t(errormessage) }}</p>
    </div>
    <div class="text-box">
      <textarea :value="feedback" @input="handleInput($event)" class="comment-box" rows="10" cols="50" maxlength="256"
        :placeholder="`${this.$t('Tell us about your experience with Ultra Play')}`
          "></textarea>
    </div>
    <button class="button-3" @click="submitData">
      {{ $t("SUBMIT") }}
    </button>

    <Loading v-if="isLoaderActive"></Loading>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { showSnackBar } from "@/utilities";

export default {
  props: {
    maxStars: {
      type: Number,
      default: 5,
    },

    rating: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const stars = Array(this.maxStars).fill(2);
    stars[0] = 2;

    return {
      stars,
      message: "",
      feedback: "",
      counter: 5,
      feedbackMessage: "",
      feedbackplaceholder: this.$t(
        "Tell us about your experience with Ultra Play"
      ),
      isLoaderActive: false,
      responseactive: false,
      errormessage: "",
      width: "30%",
      margin: "15% auto",
      radiusType: "full",
      disableSpace: false,
    };
  },

  components: {
    Loading: () =>
      import(
        /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"
      ),
  },
  computed: {},
  methods: {
    ...mapActions(["submitfeedback"]),
    updateRating(index) {
      if (this.stars[index] === 1) {
        // Clicked on a half star, set to full rating
        this.stars.splice(index, 1, 2);
      } else {
        // Clicked on an empty or full star, set to half rating
        this.stars.splice(index, 1, 1);

        // Set previous stars to full
        for (let i = index - 1; i >= 0; i--) {
          this.stars.splice(i, 1, 2);
        }

        // Set subsequent stars to empty
        for (let i = index + 1; i < this.maxStars; i++) {
          this.stars.splice(i, 1, 0);
        }
      }

      // Calculate rating based on the sum of star values (full and half)
      const fullStars = this.stars.filter((star) => star === 2).length;
      const halfStars = this.stars.filter((star) => star === 1).length * 0.5;

      // Calculate total rating including full and half stars
      this.counter = fullStars + halfStars;

      // Ensure the rating stays within the range of 0.5 to 5
      this.counter = Math.max(0.5, Math.min(5, this.counter));

      // Update the parent component with the rating
      this.$emit("rating-updated", this.counter);
    },

    handleInput(event) {
      const inputValue = event.target.value.replace(/\s+/g, " ");
      this.feedback = inputValue;
    },

    starImage(index) {
      if (this.stars[index] === 0) {
        return require("@/assets/icons/emptyrateGroup 6028.svg");
      } else if (this.stars[index] === 1) {
        return require("@/assets/icons/halfrategold.svg");
      } else {
        return require("@/assets/icons/fullrategold.svg");
      }
    },
    starAltText(starValue) {
      if (starValue === 0) {
        return "Empty star";
      } else if (starValue === 1) {
        return "Half star";
      } else {
        return "Full star";
      }
    },

    submitData() {
      if (!this.feedback.trim()) {
    showSnackBar(
      this.$t("Please enter some feedback to improve us"),
      this.$t("DISMISS")
    );
    return;
  }
      this.isLoaderActive = true;
      const data = {
        rating: this.counter,
        feedback: this.feedback,
      };
      this.submitfeedback(data)
        .then((data) => {
          // extract a success property from the response
          const success = data.data.success;
          // show a message based on the success property
          if (success) {
            // show success message
            showSnackBar(
              this.$t("Feedback submitted successfully!"),
              this.$t("DISMISS")
            );
            this.feedback = "";
            this.stars = [2, 2, 2, 2, 2];
            this.counter = 5;
            this.errormessage = "";
          } else {
            this.errormessage = this.$t(
              "Please enter some feedback to improve us"
            );
            (this.closePopup = false),
              // this.responseactive=true,
              (this.counter = 5);
            this.stars = [2, 2, 2, 2, 2];
          }
          this.isLoaderActive = false;
        })
        .catch(() => {
          // show error message
          this.isLoaderActive = false;

          showSnackBar(
            this.$t("Please check your internet connection and try again.")
          );
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.rate {
  margin-top: 20px;
  display: flex;
}

.rate div {
  margin: 0 5px;
  cursor: pointer;
}

.rate img {
  width: 30px;
  height: 30px;
}

.title-heading {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Open Sans";
}

.sub-heading {
  color: #5c5c5d;
  margin-top: 20px;
}


.comment-box:focus {
  outline: none;
  border-color: #0073FA;
}

/* Button 3 styles */
.button-3 {
  background: linear-gradient(90deg,#003AAC,#0073FA,#003AAC);
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}

.device-container {
  padding: 1.5em 3em 2rem;

  h4 {
    color: $clr-light-gd4;

    span {
      color: $clr-light-gd3;
    }
  }
}
.confirmation {
    padding: 3rem;
    border-radius: 10px;
    &-title {
      color: #c1c1c1;
      font-family: $font-regular;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 29px;
      text-align: center;
      margin-bottom: 20px;
      span {
        font-weight: bold;
      }
    }
    &-btn {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      &-align {
        width: 45%;
      }
    }


  }
  @media only screen and (max-width: 767px) {
  .comment-box {
    width: 100%;
    height: 100px;
    line-height: 20px;
  }
}

/* For devices with a screen width of 768px or more */
  @media only screen and (min-width: 768px) {
  .comment-box {
    width: 50%;
    height: 110px;
  }
}
  
</style>
